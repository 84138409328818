import React, { Component } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Header from './containers/Header';
import { createRoute } from '../utils/routeHelper';
import { routes } from '../config';


class Main extends Component {

    render() {
        return (
            <Router>
                <Header />
                <Switch>
                    {createRoute(routes)}
                </Switch>
            </Router>
        );
    }
}

export default Main;