import React, { Component } from 'react'
import { withRouter } from 'react-router';
import Logo from '../../general/Logo';

class Header extends Component {
    constructor(props) {
        super(props);
    }
    onLogoClick = () => {
        this.props.location.pathname !== '/' && this.props.history.push('/');
    }
    render() {
        return (
            <div className="header">
                <a onClick={this.onLogoClick}>
                    <Logo />
                </a>
            </div>
        )
    }
}

export default withRouter(Header);