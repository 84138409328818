import React from 'react';
import { render } from 'react-dom';
import App from './components/index';

//Importing Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
//Importing Styles
import './scss/style.scss';


const elem = document.querySelector('#app');
render (<App/>, elem)

let consoleMsg=`%cHello visitor! 👋\nI am currently working very hard revamping my portfolio. You can check out my LinkedIn: https://linkedin.com/in/saurabhiam and my GitHub: https://github.com/saurabhiam in the meantime.\nApologies for the GPU 🙏\nPeace ✌🏻`
const consoleStyle = [
    'background: #000',
    'color: #fff',
    'line-height: 14px',
    'width: 100%',
    'position: fixed',
];
console.clear();
console.log(consoleMsg, consoleStyle.join(';'));
consoleStyle.push('');
console.log(`%c Coded with ♥️ using React`, [...consoleStyle, 'padding: 10px 20px', 'line-height: 35px'].join(';'));
