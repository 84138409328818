import Home from '../components/views/Home';
import ErrorPage from '../components/views/ErrorPage';


export default [

    {
        path: `/`,
        component: Home,
        exact: true
    },
    {
        path: `/*`,
        exact: true,
        component: ErrorPage,
    },
];
