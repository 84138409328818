import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

export default class ErrorPage extends Component {
    componentDidMount = () => {

    }

    render() {
        return (
            <div className="errorPage">
                <div className="content">

                    <div className="circles">
                        <div className="typeface">
                            <h1>404<br />Page not found</h1>
                        </div>
                        <div className="circle"></div>
                        <div className="circle"></div>
                        <div className="circle"></div>
                        <div className="circle"></div>
                    </div>
                    <div className="backtoHome mt-5 text-center">
                        <Link to="/">Back to Home</Link>
                    </div>
                </div>

            </div>
        )
    }
}
