import React, { Component } from 'react';

import './style.scss';

export default class Logo extends Component {
    render() {
        return (
            <div className="logo">
                <div className="typeface">
                    <span>S</span>
                    <span>S</span>
                </div>
                <div className="circles" id="circles">
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                </div>
            </div>
        )
    }
}
