/**
* Inspired from:
* https://codepen.io/hrahimi270/pen/yLOeWxm
* https://codepen.io/saurabhsharma/pen/qBRKxje
**/


import React, { useEffect, useRef } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { BUBBLE_FRAGMENT_SHDAER, BUBBLE_VERTEX_SHADER } from './constants';
import { AdditiveBlending, Color, DynamicDrawUsage, FrontSide, Vector3 } from 'three';
import SimplexNoise from 'simplex-noise';

import './style.scss';


const Bubble = ({ position, color=['#FE390C', '#FACE40'], args }) => {
    const bubble = useRef(null);
    const simplex = new SimplexNoise();

    const processingSliderVal = 1;
    const spikesSliderVal = 0.5;
    const speedSliderVal = Math.floor(Math.random() * (20 - 10 + 1)) + 10;
    const update = () => {


        const time = performance.now() * 0.00001 * speedSliderVal * Math.pow(processingSliderVal, 3);
        const spikes = spikesSliderVal * processingSliderVal;


        const positionAttribute = bubble.current.geometry.attributes.position;
        // console.log(">>>>>", positionAttribute);
        positionAttribute.setUsage(DynamicDrawUsage);
        const vertex = new Vector3();

        for (let i = 0; i < positionAttribute.count; i++) {
            const b = vertex.fromBufferAttribute(positionAttribute, i);
            // const g = glow.geometry.vertices[i]; // glow vertices
            vertex.normalize().multiplyScalar(args[0] + 0.5 * simplex.noise4D(b.x * spikes, b.y * spikes, b.z * spikes + time, 5));
            positionAttribute.setXYZ(i, vertex.x, vertex.y, vertex.z);
        }
        positionAttribute.needsUpdate = true;
    }

    // bubble.current.deleteAttribute( 'normal' );
    useFrame(() => update())


    return (<mesh position={position} ref={bubble}>
        <circleBufferGeometry attach="geometry" args={args} />
        <shaderMaterial
            attach='material'
            uniforms={{
                color1: {
                    value: new Color(color[0])
                },
                color2: {
                    value: new Color(color[1])
                }
            }}
            vertexShader={BUBBLE_VERTEX_SHADER}
            fragmentShader={BUBBLE_FRAGMENT_SHDAER}
            side={FrontSide}
            blending={AdditiveBlending}
            transparent={true}
        />
    </mesh>)
}

export default function WobbleCircle() {
    return (
        <Canvas gl={{ antialias: true, alpha: true }} style={{position: 'fixed', overflow: 'auto' }} className="wobbleCircle" camera={{ position: [0, 0, 5], fov: 60 }}>
            <ambientLight color="#798296" />
            <directionalLight color="#fff" intensity={0.7} castShadow={true} position={[0, 500, 200]} />
            <directionalLight color="#fff" intensity={0.25} castShadow={true} position={[0, -500, 400]} />
            <Bubble position={[0, 2, 0]} args={[1.4, 128]} color={['#FE390C', '#FACE40']} />
            <Bubble position={[-3.4, -2, -2]} args={[2.7, 70]} color={['#FE390C', '#4500b5']} />
            <Bubble position={[3, -0.2, -1]} args={[2, 70]} color={['#076148', '#4dff58']} />
        </Canvas>
    )
}
